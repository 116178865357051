type GqlRequestBody = {
  query: string;
  variables: any;
  revalidate?: number;
};

export const GRAPHQL_ENDPOINT =
  "https://graphql.contentful.com/content/v1/spaces/";

export const getGraphQLEndpoint = (
  spaceId: string,
  environment: string,
): string => `${GRAPHQL_ENDPOINT}${spaceId}/environments/${environment}`;

export async function fetchFromInfoSpace(
  body: GqlRequestBody,
  tags = ["info"],
  revalidate = 3600,
) {
  // console.log({ body, tags, revalidate });
  return fetchGraphQL(
    body,
    tags,
    revalidate,
    process.env.NEXT_PUBLIC_INFO_CONTENTFUL_SPACE_ID as string,
    body.variables.preview
      ? (process.env.NEXT_PUBLIC_INFO_CONTENTFUL_PREVIEW_ACCESS_TOKEN as string)
      : (process.env.NEXT_PUBLIC_INFO_CONTENTFUL_ACCESS_TOKEN as string),
    (process.env.NEXT_PUBLIC_INFO_CONTENTFUL_ENVIRONMENT ??
      process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT) as string,
  );
}

export async function fetchFromJokeSpace(
  body: GqlRequestBody,
  tags = ["joke"],
  revalidate = 3600,
) {
  return fetchGraphQL(
    body,
    tags,
    revalidate,
    process.env.NEXT_PUBLIC_JOKE_CONTENTFUL_SPACE_ID as string,
    body.variables.preview
      ? (process.env.NEXT_PUBLIC_JOKE_CONTENTFUL_PREVIEW_ACCESS_TOKEN as string)
      : (process.env.NEXT_PUBLIC_JOKE_CONTENTFUL_ACCESS_TOKEN as string),
    (process.env.NEXT_PUBLIC_JOKE_CONTENTFUL_ENVIRONMENT ??
      process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT) as string,
  );
}

export async function fetchFromRecipeSpace(
  body: GqlRequestBody,
  tags = ["recipe"],
  revalidate = 3600,
) {
  return fetchGraphQL(
    body,
    tags,
    revalidate,
    process.env.NEXT_PUBLIC_RECIPE_CONTENTFUL_SPACE_ID as string,
    body.variables.preview
      ? (process.env
          .NEXT_PUBLIC_RECIPE_CONTENTFUL_PREVIEW_ACCESS_TOKEN as string)
      : (process.env.NEXT_PUBLIC_RECIPE_CONTENTFUL_ACCESS_TOKEN as string),
    (process.env.NEXT_PUBLIC_RECIPE_CONTENTFUL_ENVIRONMENT ??
      process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT) as string,
  );
}

export default async function fetchGraphQL(
  body: GqlRequestBody,
  tags = ["default"],
  revalidate = 3600,
  spaceId: string,
  accessToken: string,
  environment: string,
) {
  const response = await (fetch as any)(
    getGraphQLEndpoint(spaceId, environment || "master"),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
      next: {
        tags,
        revalidate: body.variables.preview ? undefined : revalidate,
      },
      cache: !body.variables.preview ? undefined : "no-store",
    },
  );

  const json = await response.json();

  if (json.errors && Array.isArray(json.errors)) {
    console.log(body.query);
    console.log(body.variables.where.slug_in);
    for (let i = 0, il = json.errors.length; i < il; i++) {
      console.error(
        json.errors[i].message,
        JSON.stringify(json.errors[i].extensions),
      );
    }
  }

  return json;
}
